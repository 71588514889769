import {createStyles, Theme, Typography, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {AppState} from '../../redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {Board} from '../../redux/boards/types';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import Button from '@material-ui/core/Button';
import {apiEndpoint, apiFetch} from '../../utils/api';
import {maxBoardTileImages} from '../../components/board/BoardTileThumbnail';
import {OptionsObject} from 'notistack';
import {enqueueSnackbar} from '../../redux/notifier/actions';
import RecommendedBoard from '../../components/RecommendedBoard';
import FabContainer from '../../components/FabContainer';
import BoardListSlider from '../../components/board/BoardListSlider';
import {Link as RouterLink} from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {showNewBoardModal} from '../../redux/show-new-board/actions';

const styles = (theme : Theme) : StyleRules => createStyles({
    teaserHeader: {
        display: 'flex',
        backgroundColor: '#fff',
        marginTop: -theme.spacing(4),
        marginLeft: -theme.spacing(8),
        marginRight: -theme.spacing(8),
        marginBottom: theme.spacing(4),
        boxShadow: '0px 7px 7px 0px rgba(0, 0, 0, .15)',
    },
    recommendedBoard: {
        flexBasis: '50%',
        overflow: 'hidden',
        marginTop: -500,
        paddingTop: 500,
    },
    teaserContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexBasis: '50%',
        backgroundColor: '#fbdd5f',
        padding: theme.spacing(4),
        '& strong': {
            fontFamily: 'AvenirNextLTW01-Bold, sans-serif',
            fontWeight: 'normal',
        },
    },
    teaserHeadline: {
        fontFamily: 'AvenirNextLTW01-Regular',
        fontWeight: 'normal',
        fontSize: 36,
        lineHeight: 1.4,
        textTransform: 'none',
    },
    bulletList: {
        paddingLeft: 0,
        listStylePosition: 'inside',
        '& > li': {
            marginBottom: theme.spacing(1),
        },
    },
    contactButtons: {
        marginTop: theme.spacing(4),
        '& > button': {
            marginRight: theme.spacing(2),
            fontFamily: 'AvenirNextLTW01-DemiBoldCn',
            fontWeight: 'normal',
            fontSize: 18,
        },
        '& > button:last-child': {
            marginRight: 0,
        },
    },
    listHeading: {
        marginBottom: theme.spacing(2),
    },
    boardListContainer: {
        marginBottom: theme.spacing(4),
    },
    boardList: {
        marginBottom: theme.spacing(1),
    },
    socialBoardList: {
        marginBottom: theme.spacing(4),
    },
    readyToExplore: {
        width: '83%',
        fontFamily: 'AvenirNextLTW01-Light, sans-serif',
        fontWeight: 'normal',
        fontSize: 34,
        marginBottom: theme.spacing(4),
        '& > strong': {
            fontFamily: 'AvenirNextLTW01-Medium, sans-serif',
            fontWeight: 'normal',
        },
    },
    noBoards: {
        marginBottom: theme.spacing(2),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface DispatchProps
{
    enqueueSnackbar : (message : string | React.ReactNode, options? : OptionsObject) => void;
    showNewBoardModal : () => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    regularBoards : Board[] | null;
    socialBoards : Board[] | null;
}

class Home extends Component<Props, State>
{
    public readonly state : State = {
        regularBoards: null,
        socialBoards: null,
    };

    public render() : ReactNode
    {
        const {classes} = this.props;
        const {regularBoards, socialBoards} = this.state;
        const requestConsultantLink = 'mailto:helpdirectme@kalisher.com?subject=New Project Request Art Lab';

        if (regularBoards === null || socialBoards === null) {
            return <InlineLoadingIndicator/>;
        }

        const myBoards = regularBoards
            .filter(board => board._user.role === 'owner' && !board._user.archived)
            .sort((a, b) => Date.parse(b.lastModifiedAt) - Date.parse(a.lastModifiedAt));

        const sharedWithMe = regularBoards
            .filter(board => board._user.role !== 'owner' && !board._user.archived)
            .sort((a, b) => Date.parse(b.lastModifiedAt) - Date.parse(a.lastModifiedAt));

        const archivedBoards = regularBoards
            .filter(board => board._user.archived)
            .sort((a, b) => Date.parse(b.lastModifiedAt) - Date.parse(a.lastModifiedAt));

        const recommendedBoard = socialBoards.length > 0 ? socialBoards[0] : null;

        return (
            <React.Fragment>
                <div className={classes.teaserHeader}>
                    <div className={classes.recommendedBoard}>
                        {recommendedBoard && (
                            <RecommendedBoard board={recommendedBoard}/>
                        )}
                    </div>
                    <div className={classes.teaserContent}>
                        <Typography variant="h1" className={classes.teaserHeadline}>
                            Welcome to Kalisher Art Lab – an amazing tool for creating and curating art collections.
                        </Typography>
                        <ul className={classes.bulletList}>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Search</strong> our library of over 15,000 pieces and build boards of
                                    artwork for projects, proposals, fun or inspiration.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Collaborate</strong> with anyone by adding them to art boards, or download
                                    as PDFs to share offline.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Add</strong> an Art Consultant to move your board from inspiration to
                                    finished art.
                                </Typography>
                            </li>
                        </ul>
                        <div className={classes.contactButtons}>
                            <Button
                                variant="contained"
                                onClick={() => window.location.href = 'mailto:jon@kalisher.com?subject=Tutorial Request Art Lab'}
                            >
                                request tutorial
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => window.location.href = requestConsultantLink}
                            >
                                contact an art consultant
                            </Button>
                        </div>
                    </div>
                </div>

                {regularBoards.length === 0 && (
                    <Typography variant="body1" className={classes.readyToExplore}>
                        <strong>Ready to Explore?</strong> Our creative engine awaits - build an art
                        board <a href="#" onClick={e => {
                            e.preventDefault();
                            this.props.showNewBoardModal();
                        }}>here</a>. <strong>Working with a team?</strong> Easily share and collaborate on your boards
                        with anyone. <strong>Ready to order?</strong> Get in touch with an Art Consultant
                        right <a href={requestConsultantLink}>here</a>. <strong>Need a PDF?</strong> Use the download
                        button on any board to pull your art into an email-ready PDF.
                    </Typography>
                )}

                <Typography variant="h2" className={classes.listHeading}>My Boards</Typography>

                {myBoards.length > 0 ? (
                    <div className={classes.boardListContainer}>
                        <BoardListSlider boards={myBoards} className={classes.boardList}/>
                        <Button
                            component={RouterLink}
                            to="/dashboard#my-boards"
                            endIcon={<ChevronRightIcon/>}
                        >
                            View My Work
                        </Button>
                    </div>
                ) : (
                    <Typography variant="body1" className={classes.noBoards}>
                        You haven't made a board yet. Start your art search in the bar at the top.
                    </Typography>
                )}

                <Typography variant="h2" className={classes.listHeading}>Shared with me</Typography>

                {sharedWithMe.length > 0 ? (
                    <div className={classes.boardListContainer}>
                        <BoardListSlider
                            boards={sharedWithMe}
                            className={classes.boardList}
                            renderAdditionalInfo={board => (
                                <Typography variant="body1">
                                    {board._user.role === 'collaborator' ? 'Collaborator' : 'Viewer'}
                                </Typography>
                            )}
                        />
                        <Button
                            component={RouterLink}
                            to="/dashboard#shared-with-me"
                            endIcon={<ChevronRightIcon/>}
                        >
                            View My Work
                        </Button>
                    </div>
                ) : (
                    <Typography variant="body1" className={classes.noBoards}>
                        You have no shared boards.
                    </Typography>
                )}

                {socialBoards.length > 0 && (
                    <React.Fragment>
                        <Typography variant="h2" className={classes.listHeading}>Art we love right now</Typography>
                        <BoardListSlider boards={socialBoards} className={classes.socialBoardList}/>
                    </React.Fragment>
                )}

                <Typography variant="h2" className={classes.listHeading}>Archived</Typography>

                {archivedBoards.length > 0 ? (
                    <div className={classes.boardListContainer}>
                        <BoardListSlider
                            boards={archivedBoards}
                            className={classes.boardList}
                            renderAdditionalInfo={board => (
                                <Typography variant="body1">
                                    {board._user.role === 'owner' ? 'Owner' : (
                                        board._user.role === 'collaborator' ? 'Collaborator' : 'Viewer'
                                    )}
                                </Typography>
                            )}
                        />
                        <Button
                            component={RouterLink}
                            to="/dashboard#archived"
                            endIcon={<ChevronRightIcon/>}
                        >
                            View My Work
                        </Button>
                    </div>
                ) : (
                    <Typography variant="body1" className={classes.noBoards}>
                        Once you are done with boards archive them, and they will live here.
                    </Typography>
                )}

                <FabContainer/>
            </React.Fragment>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        await this.loadBoards();
    }

    private async loadBoards() : Promise<void>
    {
        this.setState({regularBoards: null, socialBoards: null});

        const regularUrl = new URL(`${apiEndpoint}/boards`);
        regularUrl.searchParams.set('includeRecentImages', maxBoardTileImages.toString());

        const socialUrl = new URL(`${apiEndpoint}/boards`);
        socialUrl.searchParams.set('includeRecentImages', maxBoardTileImages.toString());
        socialUrl.searchParams.set('type', 'social');
        socialUrl.searchParams.set('archived', 'false');

        const [regularResult, socialResult] = await Promise.all([
            apiFetch(regularUrl.href),
            apiFetch(socialUrl.href),
        ]);

        if (!regularResult.ok || !socialResult.ok) {
            this.props.enqueueSnackbar('An error occurred while loading the boards.', {variant: 'error'});
            return;
        }

        const [regularData, socialData] = await Promise.all([regularResult.json(), socialResult.json()]);
        this.setState({regularBoards: regularData.items, socialBoards: socialData.items});
    }
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    enqueueSnackbar: (message : string | React.ReactNode, options? : OptionsObject) => dispatch(
        enqueueSnackbar(message, options)
    ),
    showNewBoardModal: () => dispatch(showNewBoardModal()),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        Home
    )
);
